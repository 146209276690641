import React from 'react';
import './Services.css'; // Add the CSS styles here

const Services = () => {
  return (
    <section id="services" className="services">
      <div className="container">
        <h1>Unique Education Spaces that Maximise School Learning</h1>
        <div className="columns">
          {/* Column 1 */}
          <div className="column">
            <h2>Educators</h2>
            <p>
            To ensure our mission's success, all Langwood educators come with prior tutoring experience and receive further training upon joining our team. We also constantly enrich our database of lesson plans, equipping our tutors with the necessary resources to deliver education of the highest quality.
            </p>
            {/* <a href="#" className="link">Interested in this service?</a> */}
          </div>

          {/* Column 2 */}
          <div className="column">
            <h2>Group Learning</h2>
            <p>
            Langwood Tutoring leverages the power of group learning, a method well-documented for its effectiveness by numerous educational resources, including detailed reports by the state’s education department.
            </p>
            <a href="#classes" className="link">Learn about the service →</a>
            <br />
            <a href="https://www.education.vic.gov.au/Documents/about/department/covid-19/Small_Group_Learning1.pdf" className="link" target="_blank" rel="noopener noreferrer">View Report →</a>
          </div>

          {/* Column 3 */}
          <div className="column">
            <h2>Opportunity</h2>
            <p>
            Tutoring often represents a missed opportunity in children's educational journey. Most tutoring agencies hire individuals with minimal educational experience, assigns them students, and fails to provide them with adequate training. Such tutors are typically left to their own devices, learning through trial and error. They frequently discover that simply supplementing schoolwork once a week fails to address key issues that inhibit the progress of many students.
            </p>
            {/* <a href="#" className="link">Interested in this service?</a> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
