import React from 'react';

const Home = () => {
  return (
    <section id="home" className="home">
      <div className="container">
        <h2>Nurturing Young Minds</h2>
        <p>Langwood Tutoring, based in Melbourne, provides small group learning environments that foster both academic success and personal growth.</p>
        <a href="#about" className="cta-btn">Learn More</a>
      </div>
    </section>
  );
};

export default Home;
