import React from 'react';

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <div className="container">
        <h2>Contact Info</h2>
        <a href="tel:+61411793595">0411 793 595</a>
        <br></br>
        <br></br>
        <p>Email: langwoodtutoring@gmail.com</p>
        <strong><a href="mailto:langwoodtutoring@gmail.com?subject=Tutoring%20Enquiry&body=Hello%2C%20I%20would%20like%20to%20enquire%20about%20tutoring%20services.">
        Tutoring Enquiry
        </a></strong>
      </div>
    </section>
  );
};

export default Contact;
