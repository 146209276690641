import React from 'react';

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <h2>About Langwood Tutoring</h2>
        <p>
        At Langwood Tutoring, we aspire to transform the tutoring landscape and make the most of what is undoubtedly a remarkable opportunity to nurture young minds. While the traditional one-on-one tutoring method has its benefits, research indicates that small group sessions offer more "teachable moments."
        </p>
        <p>
        Our learning environments not only aid in imparting measurable skills that prevail in school curriculums but also foster the growth of critical aspects such as student confidence, interest, self-awareness, and logic. It is this holistic development that can lead to profound improvements in academic performance.
        </p>
        <p>
        However, our objective at Langwood is not solely academic success, we believe that all the qualities important for a child's development should be integral to their formal education.
        </p>
      </div>
    </section>
  );
};

export default About;
