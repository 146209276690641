import React from 'react';

const Classes = () => {
  return (
    <section id="classes" className="classes">
      <div className="container">
        <h2>How do Classes Work?</h2>
        <ul>
          <li><strong>Immediate Start</strong>: Classes begin as soon as a student enrolls, starting with one-on-one sessions if necessary. As more students join, groups of up to four are formed.</li>
          <li><strong>Schedule</strong>: Classes are held weekly from 4:00 PM to 5:30 PM.</li>
          <li><strong>Location</strong>: Parents take turns hosting the classes. However, opting out of hosting due to circumstances is perfectly acceptable.</li>
          <li><strong>Cost</strong>: Each session is priced at $60 per student in a group class. $80 for a student participating in one-on-one lessons.</li>
        </ul>
        <br />
        <h3>Benefits for Your Child</h3>
        <ul>
          <li><strong>Enhanced Understanding</strong>: Students will improve their literacy, numeracy, and understanding of science.</li>
          <li><strong>Musical Integration</strong>: Music, particularly percussion, is a core part of some exercises, enhancing engagement, confidence, and offering a fresh perspective on language.</li>
          <li><strong>Collaborative Skills</strong>: Students learn to work effectively in groups and develop their ideas by appreciating diverse perspectives.</li>
          <li><strong>Personal Expression</strong>: Continuous development of each student’s own voice to articulate ideas and ask questions more effectively.</li>
          <li><strong>Reduced Fear of Failure</strong>: Exposure to group activities significantly lowers anxiety about making mistakes.</li>
          <li><strong>Positive Schooling Perception</strong>: Fosters a stronger personal connection to education through positive learning experiences.</li>
        </ul>
        <br />
        <h3>Why Choose Langwood Tutoring?</h3>
        <p>
          The inspiration behind Langwood’s name — and in part its philosophy — stems from combining the names of my parents. I've been tutoring for over ten years, and my parents have always set an example for me of just how significant an impact we can have on each other's lives. After quitting my work for another tutoring agency, my ambition to harness my experiences and craft a nurturing educational environment began in the form of this organization. At Langwood, we are driven by a commitment to realize our students' potential through progressive techniques and dedicated effort.
        </p>
      </div>
    </section>
  );
};

export default Classes;
